export default {
  // AUTHENTICATE_LOGIN: "ViViewCustomer/login",
  AUTHENTICATE_LOGIN: "ViViewCustomer/login-agency",

  AUTHENTICATE_SIGNUP: "User/create",
  AUTHENTICATE_FORGOT_PASSWORD: "User/ForgetPassword",
  SYNC_INFO: "user/SyncInfo",
  FORGOT_PASSWORD_OTP_PORTAL: "ViViewCustomer/check-phone-agency",
  CONFIRM_OTP_PORTAL: "/vi-VN/AccountPortal/OTPConfirmPassword",
};
