import {
  ENVIRONMENT_BASE_URL_API,
  ENVIRONMENT_STNHD_URL_API,
  ENVIRONMENT_TTL_URL_API,
} from "./environment";
export const BASE_URL_API = ENVIRONMENT_BASE_URL_API;
export const STNHD_URL_API = ENVIRONMENT_STNHD_URL_API;
export const TTL_URL_API = ENVIRONMENT_TTL_URL_API;

export default {
  /*Common*/
  GET_COMMON_GET_CITY: "/Province",
  GET_COMMON_GET_DISTRICT: "/District",

  //setting column affiliate
  SAVE_SETTING_COLUMN: "/AffiliateReportSettings/update-setting",
  GET_SETTING_COLUMN: "/AffiliateReportSettings/get-setting-for-user",
  OPTION_LIST_GROUPCODE: "/MstOptions/list/groupcode",
};
