import API from "@Modules/Authenticate/configs/api";
import ApiTTLMethod from "@utility/ApiTTLMethod";
import ApiMethod from "@utility/ApiMethod";

export const callForgotPassword = (payload) => {
  return ApiMethod.post(API.FORGOT_PASSWORD_OTP_PORTAL, payload);
};

export const confirmOTP = (payload) => {
  return ApiTTLMethod.post(API.CONFIRM_OTP_PORTAL, payload);
};
